/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import axios from "axios";
import PARSE from "html-react-parser";
import Api from "./Api";
// import HtmlReactParse from 'html-react-parser';
import moment from "moment";
import Styles from "./TBS_Homepage_Share_Prices.module.css";
import { Link } from "react-router-dom";

function TBS_Homepage_Share_Prices() {
  const [section, setSection] = useState([]);
  const [errorGetFirstStock, setErrorGetFirstStock] = useState(false);

  const [stockData, setStockData] = useState([]);
  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getData = useCallback(() => {
    axios
      .get("https://api.bzpublish.com/utilities/stock_market_detail/")
      .then((response) => {
        setErrorGetFirstStock(false);
        setStockData(response?.data.optionChain.result[0]?.quote);
      })
      .catch((error) => {
        setErrorGetFirstStock(true);
        console.error("Error fetching stock data:", error);
        // Optionally, update error state to display an error message to the user
      });
  }, []);
  
  useEffect(() => {
    getData();
  }, []);

  // const getDataBackup = useCallback(() => {
  //   axios
  //     .get(
  //       "https://cors-anywhere.herokuapp.com/query2.finance.yahoo.com/v6/finance/options/TOBA.JK"
  //     )
  //     .then((response) => {
  //       console.log("🚀 ~ .then ~ response:", response);
  //       setStockData(response?.data.optionChain.result[0]?.quote);
  //     });
  // }, []);
  // useEffect(() => {
  //   if (errorGetFirstStock) getDataBackup();
  // }, [errorGetFirstStock]);

  useEffect(() => {
    const fetchData = async () => {
      const api = new Api();

      try {
        const sectionResponse = await api.getSectionDetail({
          language: activeLang,
        });
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0].widgets;

        setSection(sections);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [activeLang]);

  const nominalFormatter = (num) => {
    if (9999 < num > 999) {
      if (num % 1000 === 0) {
        return (num / 1000).toFixed(3) + "K";
      } else {
        return (num / 1000).toFixed(3) + "K";
      }
    } else if (num > 99999) {
      if (num % 1000000 === 0) {
        return (num / 1000000).toFixed(3) + "M";
      } else {
        return (num / 1000000).toFixed(3) + "M";
      }
    }
  };

  let investorsContent;
  let sharePrices;
  const text = [];
  if (section) {
    section.forEach((data, id) => {
      const { widget_contents } = data;

      if (id === 0) {
        investorsContent = widget_contents[0];
        sharePrices = widget_contents[1];
      }

      if (id === 1) {
        widget_contents &&
          widget_contents.forEach((val) => {
            text.push(val?.content);
          });
      }
    });
  }

  const regularMarketChangePercent = (bid, open) => {
    return parseFloat(((bid - open) / open) * 100).toFixed(2);
  };

  const regularmarketchange = (bid, open) => {
    return bid - open;
  };

  const regularmarketchangeRounded = Math.round(
    regularmarketchange(stockData?.bid, stockData?.regularMarketOpen)
  );

  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row fhd:mx-auto fhd:max-w-[1536px] mt-[80px]">
        <div className="w-full h-[553px] bg-gray-200">
          <div
            className={`mx-auto max-w-[17rem] xs:max-w-[21rem] s:max-w-[28.1rem] ${Styles["left-side"]}`}
          >
            <div className="h-full flex flex-col justify-between">
              <div className={Styles["left-side-content-1"]}>
                <h1>{sharePrices?.article_language?.title}</h1>
                <h2>
                  {stockData?.bid !== 0
                    ? stockData?.bid
                    : stockData?.regularMarketPreviousClose}
                </h2>
                <p
                  className={
                    Math.round(
                      regularmarketchange(
                        stockData?.bid,
                        stockData?.regularMarketOpen
                      )
                    ) > 0
                      ? `text-green`
                      : `text-rose-500`
                  }
                >
                  {`${regularmarketchangeRounded} 
                  (${
                    stockData?.regularMarketOpen === 0
                      ? "0%"
                      : regularMarketChangePercent(
                          stockData?.bid,
                          stockData?.regularMarketOpen
                        ) + "%"
                  })`}
                </p>
              </div>
              <div className={Styles["left-side-content-2"]}>
                <div className="flex">
                  <h2 className="w-[120px]">{text[0]}</h2>
                  {/* <p>{nominalFormatter(stockData?.regularMarketVolume?.raw)}</p> */}
                  <p>
                    {stockData?.regularMarketVolume === 0
                      ? "0"
                      : nominalFormatter(stockData?.regularMarketVolume)}
                  </p>
                </div>
                <div className="flex">
                  <h2 className="w-[120px]">{text[1]}</h2>
                  <p>{moment().format("DD MMMM YYYY")}</p>
                </div>
                <div className="flex">
                  <h2 className="w-[120px]">{text[2]}</h2>
                  <p>
                    {regularmarketchange(
                      stockData?.bid,
                      stockData?.regularMarketOpen
                    )}
                  </p>
                </div>
              </div>
              <div className={`group ${Styles["left-side-content-link"]}`}>
                <Link
                  className="group w-fit"
                  to={`${sharePrices?.article_language?.path}`}
                >
                  <p className="group-hover:mr-2 font-semibold">
                    {sharePrices?.article_language?.summary}
                  </p>
                  <ArrowRightIcon className="" color="white" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[553px] bg-blue-500">
          <div
            className={`mx-auto max-w-[17rem] xs:max-w-[21rem] s:max-w-[28.1rem] ${Styles["right-side"]}`}
          >
            <div className="h-full flex flex-col justify-between">
              <div className={Styles["right-side-content-title"]}>
                <h2 className="mb-5 text-[32px] font-bold">
                  {investorsContent?.article_language?.title}
                </h2>
                <p>
                  {investorsContent &&
                    PARSE(investorsContent?.article_language?.content)}
                </p>
              </div>
              <div className={`group ${Styles["right-side-content-link"]}`}>
                <Link
                  className="group w-fit"
                  to={`${investorsContent?.article_language?.path}`}
                >
                  <p className="group-hover:mr-2 font-semibold">
                    {investorsContent?.article_language?.summary}
                  </p>
                  <ArrowRightIcon className="" color="white" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TBS_Homepage_Share_Prices;
